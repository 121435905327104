<template>
  <div>
    <window-header></window-header>
    <nav-form>
      <template v-slot:before>
        <button
          v-if="winOptions.selectable && mode=='view'"
          class="button button-fill button-color-primary"
          @click="winOptions.onSelect(self)"
        >
          <fa-icon icon="mouse-pointer" />
          <span>Seleccionar</span>
        </button>
      </template>
    </nav-form>
    <section style="display:inline-block;">
      <article style="width:250px; height:536px">
        <field
          name="codigo"
          widget="input"
          label="Código"
          placeholder="Cod"
          width="50px"
          required
          inputStyle="font-weight:bold; text-align:center;"
          style="top:9px; left:6px;"
          searchable
          :readonly="mode=='edit'"
        />
        <field
          ref="main-image"
          widget="image"
          name="imagen"
          style="width:238px; height:238px; bottom: 10px; left:5px;"
        />
      </article>
    </section>
    <section style="float:right;width:700px;height:526px;margin:10px;" class="rlist-list">
      <span
        style="position: absolute; bottom:17px; right: 23px; background:#ddd; min-width:40px; text-align:center;"
      >{{count}}</span>
      <template v-if="items.length">
        <div
          @click="loadItem(item[primary])"
          v-for="item in items"
          :key="item[primary]"
          class="rlist-item"
          :class="{'rlist-item-selected':item[primary]==itemId}"
        >
          <div style="text-align:right;">{{item.codigo}}</div>
          <img
            :src="item.imagen? item.imagen : require('./../assets/noimage.png')"
            style="width:100%"
          />
        </div>
      </template>
      <div v-else>Sin resultados</div>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  watch: {
    mode: function(newVal, oldVal) {
      var self = this;
      if (newVal == "new") {
        window.console.log(self.$refs["main-image"].$children[0].selectImage());
      }
    }
  },
  data: function() {
    return {
      title: "Galería de proveedores",
      dbAdapter: "imagen_proveedor",
      primary: "codigo",
      sequence: { name: "imagen_proveedor" }
    };
  }
};
</script>
<style>
</style>
